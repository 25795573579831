<template>
  <div>
    <el-timeline-item
      v-if="content"
      :icon="icon"
      :type="type"
      :color="color"
      size="large"
      :timestamp="formatDate(activity.createdOn)"
    >
      <div v-html="formattedActivityText" class=""></div>
    </el-timeline-item>
  </div>
</template>

<script>
import ActivitiesTimelineMixin from "@/components/ActivitiesView/ActivitiesTimelineMixin.vue";
export default {
  mixins: [ActivitiesTimelineMixin],
  props: {
    activity: {
      type: Object,
      required: true
    },
    atlasFiles: {
      type: Object,
      required: false
    }
  },
  methods: {
    formatActivityDescription() {
      if (!this.activity?.data?.updates?.length) return "uploaded a document";

      const { category, updates } = this.activity.data;
      const updateDescriptions = updates
        .map(update => `titled ${update.field}`)
        .join(", ");

      return `uploaded document(s) ${updateDescriptions} to a document category titled ${category ??
        "None"}`;
    }
  },
  watch: {
    activity: {
      async handler() {
        this.content = "";
        this.icon = "";
        this.type = "";
        this.link = {};
        this.color = "";
        switch (this.activity.name) {
          case "DELETE_FILE_SUCCESS":
            this.generateReadableGeneric({
              content: `deleted a file`,
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "DELETE_FILE_FAILURE":
            this.generateReadableFailGeneric({
              content: `failed to delete a file`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
          case "FILE_UPLOAD_SUCCESS":
            this.generateReadableGeneric({
              content: this.formatActivityDescription(),
              icon: "el-icon-document",
              type: "success",
              link: this.accountTypeFormatted
            });
            break;
          case "FILE_UPLOAD_FAILURE":
            this.generateReadableFailGeneric({
              content: `failed upload document`,
              icon: "el-icon-document",
              link: this.accountTypeFormatted
            });
            break;
        }
      },
      immediate: true
    }
  }
};
</script>
